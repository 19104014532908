import $ from "./cashwrapper";
import Dropdown from "./surplex.Dropdown";
;(function ($,SURPLEX) {
    SURPLEX.Search = $.extend({}, {
        SEARCH_CATEGORY_TERM: 'term',
        SEARCH_CATEGORY_NUMBER: 'number',
        XHR : undefined,
        TYPINGTIMER : undefined,
        initialize: function () {
            this.enableListeners();
        },

        initializeLoadEvents: function () {
            this.checkSearchPageResult();
        },

        searchHistory : {
            historyKey : 'search-history',
            addSearch: function (search) {
                let history = SURPLEX.Utils.storage.get(this.historyKey) || [];
                if(typeof history === "object" && typeof search.text !== 'undefined'){
                    if (!this.findValue(history, search.text)) {
                        search.link = search.link.replace(/=manufacturer|=category|=term|=article/gi, '=previousSearches');
                        history.push(search);
                        while (history.length > 5){
                            history.shift();
                        }
                        SURPLEX.Utils.storage.set(this.historyKey, history);
                    }
                }
            },

            getSearch:function(){
                let history = SURPLEX.Utils.storage.get(this.historyKey) || [];
                if(typeof history === "object" && history.length > 0){
                    return history;
                }
                return false;
            },

            clearSearch: function () {
                SURPLEX.Utils.storage.remove(this.historyKey);
            },

            findValue: function (arr, value) {
                for (let i=0; i < arr.length; i++) {
                    if (arr[i]['text'].toLowerCase() === value.toLowerCase()) {
                        return true;
                    }
                }
                return false;
            }
        },
        enableListeners: function () {
            let $searchInput = $('#searchInput');

            SURPLEX.App.uniqueBindEvent('#searchButton', 'click', 'search', function (e) {
                SURPLEX.Search.removeSuggestionSelection();
                SURPLEX.Search.getResultPage($('#searchInput'));
            });

            SURPLEX.App.uniqueBindEvent('.js_clear-search', 'click', 'search', function (e) {
                $('#searchInput').val('').click().focus();
                $('.header--search').removeClass('is-typing');
                SURPLEX.Search.showSuggestion();
            });

            $searchInput.closest('.dropdown').get(0).addEventListener('show.bs.dropdown', function(e){
                const $searchInputField = $("#searchInput");
                if ($searchInputField.val().length < 2 && SURPLEX.Search.searchHistory.getSearch() === false) {
                    e.preventDefault();
                } else {
                    SURPLEX.Search.showSuggestion();
                }
            });

            $searchInput.closest('.dropdown').get(0).addEventListener('hide.bs.dropdown', function (e) {
                const $searchInputField = $("#searchInput");
                if (document.activeElement === $searchInputField.get(0)
                    && $searchInputField.val().length > 1
                    && $("#searchSuggestions").html().length > 0) {
                    e.preventDefault();
                } else if(document.activeElement === $searchInputField.get(0) && SURPLEX.Search.searchHistory.getSearch() !== false
                    && $searchInputField.val().length === 0) {
                    e.preventDefault();
                }
            });

            SURPLEX.App.uniqueBindEvent('#searchInput', 'keyup', 'search', function (e) {
                let curSelected = $("#searchSuggestions li.selected");
                let $searchTyping = $('.header--search');
                let $searchInput = $('#searchInput');
                if($searchInput.val().length > 0){
                    if(!$searchTyping.hasClass('is-typing')){
                        $searchTyping.addClass('is-typing');
                    }
                } else {
                    if($searchTyping.hasClass('is-typing')){
                        $searchTyping.removeClass('is-typing');
                    }
                }

                switch (e.keyCode) {
                    case 38:
                        // Cursor-up
                        SURPLEX.Search.removeSuggestionSelection();
                        if (curSelected.length < 1) {
                            // No selection -> start with first child
                            curSelected = $("#searchSuggestions ul").children('.linkList--item').last();
                        } else {
                            if (curSelected.prev().length > 0 && curSelected.prevAll ( '.linkList--item' ).length > 0) {
                                // if it is not the first li move one up
                                do {
                                    curSelected = curSelected.prev();
                                } while (!curSelected.hasClass("linkList--item") && (curSelected.prev().length > 0))
                            } else {
                                curSelected = $("#searchSuggestions ul").children('.linkList--item').last();
                            }
                        }
                        if (curSelected) {
                            SURPLEX.Search.setSuggestionSelection(curSelected);
                        }
                        break;
                    case 40:
                        // Cursor Down
                        SURPLEX.Search.removeSuggestionSelection();
                        if (curSelected.length < 1) {
                            // No selection -> start with first child
                            curSelected = $("#searchSuggestions ul").children('.linkList--item').first();
                        } else {
                            if (curSelected.next().length > 0) {
                                // if it is not the last li move one down
                                do {
                                    curSelected = curSelected.next();
                                } while (!curSelected.hasClass("linkList--item") && (curSelected.next().length > 0))
                            } else {
                                curSelected = $("#searchSuggestions ul").children('.linkList--item').first();
                            }
                        }
                        if (curSelected) {
                            SURPLEX.Search.setSuggestionSelection(curSelected);
                        }
                        break;
                    case 13: //ENTER
                        SURPLEX.Search.getResultPage($searchInput);
                        break;
                    default:
                        SURPLEX.Search.showSuggestion();

                }
            });
        },

        getResultPage: function ($searchInput) {
            try {
                let searchWord = $searchInput.val();
                let $curLink = $('#searchSuggestions li.selected a');
                let curLink = $curLink.attr('href');

                if (curLink && (curLink.length > 0)) {
                    SURPLEX.Search.searchHistory.addSearch({'text':$curLink.attr('title'),'link':curLink});
                    window.location.href = curLink;
                } else {
                    let searchLink = SURPLEX.Utils.decodeBase($searchInput.data('search-url'));
                    let searchCategory = SURPLEX.Search.SEARCH_CATEGORY_TERM;

                    if (/^[0-9]+$/.test(searchWord)) {
                        searchCategory = SURPLEX.Search.SEARCH_CATEGORY_NUMBER;
                    }

                    if (searchWord.length > 0) {
                        window.location.href = searchLink + "?search=" + encodeURIComponent(searchWord) + "&searchcategory=" + searchCategory;
                    }
                }
            } catch (err) {
                //console.log(err);
            }
        },

        removeSuggestionSelection: function () {
            try {
                //remove hover-like effect from LI>A
                // remove selection from LI
                $('#searchSuggestions li.selected').removeClass('selected');
            } catch (err) {

            }
        },

        setSuggestionSelection: function (elem) {
            try {
                elem.addClass('selected');
            } catch (err) {

            }
        },

        showSearchHistory: function(searchHistory){
            let $searchInput = $('#searchInput');
            let $searchSuggestions = $("#searchSuggestions");
            if(searchHistory !== false) {
                let nthList = [];
                searchHistory.reverse().forEach(function (el) {
                    nthList.push(
                        $('<li>').attr('class', 'linkList--item')
                            .append(
                            $('<a>').attr('href', el.link)
                                .attr('title', unescape(decodeURI(el.text)))
                                .html(unescape(decodeURI(el.text)))
                        )
                    );
                });

                $searchSuggestions.html(
                    '<div class="search__dropdownItem search__dropdownItem--searchHistory pt-4 js-searchHistory">' +
                            '<div class="search__dropdownItemHeader">' +
                                '<h5 class="label">' + $searchInput.data('message-searched') + '</h5>' +
                            '</div>' +
                            '<ul class="linkList"></ul>' +
                        '</div>');

                let clear =
                    $('<span>').attr({'class': 'search__dropdownItemDelete', 'title': $searchInput.data('message-clear')}).append(
                        $('<span>').attr('class', 'icon__delete')
                            .click(function () {
                                SURPLEX.Search.searchHistory.clearSearch();
                                $searchSuggestions.html('');
                                SURPLEX.Search.hideDropdown();
                            })
                    );
                $('.js-searchHistory .search__dropdownItemHeader').append(clear);
                $('.js-searchHistory .linkList').append(...nthList);
            }
        },

        showDropdown(){
            let searchDD = document.getElementById('search-dropdown');
            if(searchDD === null || searchDD.classList.contains('show')){
                return;
            }
            let ddFilter = Dropdown.getInstance(searchDD);
            if(ddFilter !== null){
                ddFilter.show();
            }
        },

        hideDropdown(){
            let searchDD = document.getElementById('search-dropdown');
            if(searchDD === null || !searchDD.classList.contains('show')){
                return;
            }
            let ddFilter = Dropdown.getInstance(searchDD);
            if(ddFilter !== null){
                ddFilter.hide();
            }
        },

        showSuggestion: function () {
            let $searchInput = $('#searchInput');
            let searchTag = $searchInput.val();
            let searchLast = $searchInput.data('mostrecent');
            let $searchSuggestions = $("#searchSuggestions");
            let searchHistory = SURPLEX.Search.searchHistory.getSearch();
            $searchSuggestions.removeClass('search-history');
            if (searchTag.length < 2) {
                if (searchHistory === false) {
                    SURPLEX.Search.hideDropdown();
                } else {
                    SURPLEX.Search.showSearchHistory(searchHistory);
                }
                return;
            }

            if (searchTag === searchLast) {
                if ($searchSuggestions.html().length === 0) {
                    SURPLEX.Search.hideDropdown();
                }
                let $history = $searchSuggestions.find('.js-searchHistory');
                if (typeof $history === 'undefined' || $history.length === 0) {
                    return;
                }
            }
            $searchInput.data('mostrecent', searchTag);

            if(typeof SURPLEX.Search.XHR !== "undefined" && SURPLEX.Search.XHR.readyState !== 4){
                SURPLEX.Search.XHR.abort('abort');
            }
            if(typeof SURPLEX.Search.TYPINGTIMER === "number"){
                clearTimeout(SURPLEX.Search.TYPINGTIMER);
                SURPLEX.Search.TYPINGTIMER = undefined;
                $searchSuggestions.addClass('is-loading');
            }
            SURPLEX.Search.TYPINGTIMER = setTimeout(loadSearchResult, 400);

            function loadSearchResult(){
                SURPLEX.Search.XHR = $.ajax({
                    url: SURPLEX.Utils.decodeBase($searchInput.data('suggestion-url')),
                    data: {search: searchTag}
                }).done(function (data) {
                    if(searchTag === $searchInput.val()){
                        if (data.length > 0) {
                            $searchSuggestions.html(data);
                            $searchSuggestions.find('a').not('.redirect').off('click').on('click', function () {
                                SURPLEX.Search.searchHistory.addSearch({'text':$(this).attr('title'),'link':$(this).attr('href')});
                            });
                            if (!$searchSuggestions.hasClass('show')) {
                                SURPLEX.Search.showDropdown();
                            }
                        } else {
                            $searchSuggestions.html('');
                            SURPLEX.Search.hideDropdown();
                            SURPLEX.Search.removeSuggestionSelection();
                        }
                        $searchInput.focus();
                    }
                }).fail(function (xhr) {
                    if (xhr.status === 0 && xhr.statusText === 'abort') {
                        return;
                    }
                    $searchSuggestions.html('');
                    SURPLEX.Search.hideDropdown();
                    SURPLEX.Search.removeSuggestionSelection();
                }).always(function(){
                    $searchSuggestions.removeClass('is-loading');
                });
            }
        },

        checkSearchPageResult() {
            if(SURPLEX.Track.getDataFromLayer('dataLayer', 'PageType') === 'search'){
                let canonicalUrl = SURPLEX.Track.getDataFromLayer('dataLayer', 'canonicalUrl');
                let params = canonicalUrl.slice(canonicalUrl.indexOf('?') + 1).split('&');
                let vars = [], val;
                let i = params.length-1;
                do {
                    val = params[i].split('=');
                    vars.push(val[0]);
                    vars[val[0]] = val[1];
                } while(i--);
                if(vars['searchcategory'] && vars['searchcategory'] !=='zeroresults'){
                    SURPLEX.Search.searchHistory.addSearch(
                        {'text':vars['search'], 'link':canonicalUrl});
                }
            }
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Search);
    }
})($,SURPLEX);