import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    SURPLEX.Utils = $.extend({}, SURPLEX.Utils, {

        /**
         * Prüfe ob ein Element mit einer bestimmten Klasse "c" existiert und führt die Funktion "f" aus
         *
         * Beispiel:
         * <code>
         * SURPLEX.Utils.runIfClassNamePresent('cmxform', this.validateForms);
         * </code>
         *
         * @memberof SURPLEX.Utils
         *
         * @param {string} c class name
         * @param {function} f function name
         */
        runIfClassNamePresent: function (c, f) {
            var n = document.getElementsByClassName(c);
            n.length > 0 && f()
        },

        /**
         * check in dom via data-enable-plugins attribute, if any js plugins have to be enabled for that section
         *
         * Beispiel 1:
         * <code>
         * <div class="swiper" id="swiper" data-enable-plugins="swiper" data-swiper='{"pagination": "#swiper-pagination", "loop": false, "grabCursor": true, "slidesPerView": "1", "paginationClickable":true}'>
         * </code>
         *
         * Beispiel 2:
         * <code>
         * <div data-enable-plugins="myFancyPlugIn" data-myfancyplugin='{"some": "configuration", "someOther": "configuration"}, "Parameter 2 as String"'>
         * </code>
         * @memberof SURPLEX.Utils
         *
         * @param {jQuery} $section
         */
        enablePlugInsForSection: function ($section) {
            var $sectionsWithPlugIns = $section.filter('[data-enable-plugins]').add($section.find('[data-enable-plugins]'));
            $sectionsWithPlugIns.each(
                function (sectionIndex, subSection) {
                    var plugIns = $(subSection).data('enable-plugins').replace(" ", "").split(',');
                    // iteriere über alle plugins
                    $.each(plugIns,
                        function (pluginIndex, plugInName) {
                            var plugInArguments = $(subSection).data(plugInName.toLowerCase());
                            switch (typeof plugInArguments) {
                                case 'object':
                                    plugInArguments = {'config': [plugInArguments]};
                                    break;
                                case 'string':
                                    try {
                                        plugInArguments = $.parseJSON('{"config":[' + plugInArguments.replace(/^\s*$/g, '') + ']}');
                                    } catch (e) {
                                        plugInArguments = {'config': []};
                                    }
                                    break;
                                default:
                                    plugInArguments = {'config': []};
                                    break;
                            }
                            if (typeof $(subSection)[plugInName] === "function") {
                                $(subSection)[plugInName].apply($(subSection), plugInArguments['config']);
                            }
                        }
                    );
                }
            );
        },

        /**
         * JavaScript Datei laden und anschließend eine call-back Funktion aufrufen
         *
         * Beispiel:
         * <code>
         * SURPLEX.Utils.loadScript('/typo3conf/ext/surplex/Resources/Template/website/js/jquery.validate.js', this.validateForms);
         * </code>
         *
         * @memberof SURPLEX.Utils
         *
         * @param {string} url
         * @param {function} callback
         */
        loadScript: function (url, callback) {
            var script = document.createElement("script");
            if (script.readyState) {  //IE
                script.onreadystatechange = function () {
                    if (script.readyState === "loaded" || script.readyState === "complete") {
                        script.onreadystatechange = null;
                        callback();
                    }
                };
            } else {  //Others
                script.onload = function () {
                    callback();
                };
            }
            script.src = url;
            document.getElementsByTagName("head")[0].appendChild(script);
        },

        isJSON: function (s) {
            if (typeof s === "object") {
                return s;
            }
            var isjson = true;
            var json;
            try {
                json = JSON.parse(s);
                if(json === null){
                    isjson = false;
                }
            } catch (err) {
                isjson = false;
            }
            return isjson ? json : false;
        },

        isInViewport ($el) {
            if( $el.length < 1 ) {
                return;
            }
            let rect = $el.get(0).getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
            );
        },

        isMobile: function () {
            return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) ? !0 : !1
        },

        isIos: function () {
            return navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)  ? !0 : !1
        },

        isTouchDevice : function () {
            return 'ontouchstart' in window  || 'msmaxtouchpoints' in window.navigator;
        },

        isMobileViewport: function () {
            return (window.innerWidth < 768);
        },

        isTabletViewport: function () {
            return (window.innerWidth >= 768 && window.innerWidth < 992);
        },

        isDesktopViewport: function () {
            return (window.innerWidth >= 992 && window.innerWidth < 1200);
        },

        isDesktopLargeViewport: function () {
            return (window.innerWidth >= 1200);
        },

        isPortrait: function () {
            return (window.innerHeight > window.innerWidth);
        },

        isLandscape: function () {
            return (window.innerWidth > window.innerHeight);
        },

        /**
         * zu einem bestimmten element scrollen
         * target kann ein hash tag sein oder position in px
         * @memberof SURPLEX.Utils
         *
         * @param {String|Number} target        Target position or Hash tag
         * @param {Number}        [duration]    Duration for scrolling. Defaults to 1000
         * @param {Function}      [onComplete]  Callback function to execute after scrolling
         * @param {String|Number} [easingType]    Target position or Hash tag
         */
        scrollTo: function (target, duration, onComplete, easingType) {
            var targetOffsetTop = 0;
            switch (typeof target) {
                case 'string':
                    targetOffsetTop = $(target).length ? $(target).offset().top : 0;
                    break;
                case 'object':
                    if (target.hasOwnProperty('selector')) {
                        targetOffsetTop = $(target.selector).offset().top;
                    }
                    break;
                case 'number':
                    targetOffsetTop = target;
                    break;
                default:
                    return;
            }
            window.scroll({top: targetOffsetTop,  behavior: 'smooth'});
        },

        /**
         * @memberof SURPLEX.Utils
         *
         * @param {String} str Encoded string
         */
        decodeBase: function (str) {
            try {
                return decodeURIComponent(escape(window.atob(str)));
            } catch(e){
                return str;
            }
        },

        setCookie : function(key, value, expire) {
            if (navigator.cookieEnabled) {
                if (expire === undefined) {
                    expire = 31536000000;  //1 year in ms
                }
                let expires = new Date();
                expires.setTime(expires.getTime() + expire);
                document.cookie = key + '=' + value + ';path=/;expires=' + expires.toUTCString();
                return this.getCookie(key) !== null;
            }
            return false;
        },

        getCookie : function (key) {
            let keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
            return keyValue ? keyValue[2] : null;
        },

        isEmpty: function (el) {
            return !$.trim(el.html())
        },

        rtrim: function (s, c) {
            return s.replace('/' + c + '+$/', '');
        },

        ltrim: function (s, c) {
            return s.replace('/^' + c + '+/', '');
        },

        createUUID : function () {
            let d = new Date().getTime();
            if (window.performance && typeof window.performance.now === "function")
            {
                d += performance.now(); //use high-precision timer if available
            }
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        },

        hasClass : function (element, className){
            return element.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(element.className);
        },

        /**
         * usage example: SURPLEX.Utils.getRandomOption([{'metal' : 2}, {'wood' : 1}, {'cross' : 1}])
         * these are my options with their weights metal: 50%, wood: 25%, cross: 25%
         *
         * @param optionsWithWeights
         * @returns {*}
         */
        getRandomOption: function(optionsWithWeights) {
            const probabilitiesOptionFactory = optionsWithWeights;
            let weightedOptions = [];

            probabilitiesOptionFactory.forEach(function(obj, index){
                for (let key in obj){
                    for (let loop = 0; loop < obj[key]; loop ++){
                        weightedOptions.push(key)
                    }
                }
            });
            return weightedOptions[Math.floor(Math.random() * weightedOptions.length)];
        },

        jqueryHiddenSelector: function(index) {
            return !SURPLEX.Utils.jqueryVisibilityCheck(this);
        },

        jqueryVisibleSelector: function(index) {
            return SURPLEX.Utils.jqueryVisibilityCheck(this);
        },

        jqueryVisibilityCheck: function(elem) {
            if (elem instanceof $) {
                elem = elem.get(0);
            }
            return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
        },

        storage: {
            set: function(key, value) {
                if(!('localStorage' in window)){
                    return;
                }
                if (!key || !value) {return;}
                if (typeof value === "object") {
                    value = JSON.stringify(value);
                }
                try {
                    window.localStorage.setItem(key, value);
                } catch (e) {}
            },
            get: function(key) {
                if(!('localStorage' in window)){
                    return;
                }
                try {
                    let value = window.localStorage.getItem(key);
                    if (!value) {return;}
                    if (value[0] === "{" || value[0] === "[") {
                        value = JSON.parse(value);
                    }
                    return value;
                } catch (e) {}
            },
            remove: function (key) {
                if(!('localStorage' in window)){
                    return;
                }
                try {
                    window.localStorage.removeItem(key);
                } catch (e) {}
            }
        },
    });
})($, SURPLEX);