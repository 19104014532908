(function(e, d, w) {
    if(!e.composedPath) {
        e.composedPath = function() {
            if (this.path) {
                return this.path;
            }
            let target = this.target;

            this.path = [];
            while (target.parentNode !== null) {
                this.path.push(target);
                target = target.parentNode;
            }
            this.path.push(d, w);
            return this.path;
        }
    }
})(Event.prototype, document, window);
if(!Object.entries){
    Object.entries = function(obj) {
        return Object.keys(obj).reduce(function(arr, key) {
            arr.push([key, obj[key]]);
            return arr;
        }, []);
    }
}
import Alert from "bootstrap/js/dist/alert";
import Button from "bootstrap/js/dist/button";
import Collapse from "./surplex.Collapse";
import Dropdown from "./surplex.Dropdown";
import Modal from 'bootstrap/js/dist/modal'
import Popover from 'bootstrap/js/dist/popover';
import Tab from 'bootstrap/js/dist/tab';
import Toast from 'bootstrap/js/dist/toast';