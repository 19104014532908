/**
 * @param {$} $ jQuery object
 * @param {SURPLEX} SURPLEX namespace
 */

import Modal from "bootstrap/js/dist/modal";
import Dropdown from "./surplex.Dropdown";
import Popover from "bootstrap/js/dist/popover";
import $ from "./cashwrapper";

;(function ($, SURPLEX, undefined) {
    SURPLEX.SearchAgent = $.extend({}, {
        initialize: function () {
            this.enableListeners();
        },
        enableListeners: function () {

            SURPLEX.App.uniqueBindEvent(
                '.filterBtn',
                'click',
                'SearchAgent',
                function(e)
                {
                    if (!SURPLEX.App.getIsGuest()) {
                        $(this).removeClass('show');
                        let $ddBox = $($(this).data('bs-target'));
                        let dropdownFilterAbo = $(this).get(0);
                        let ddFilter = Dropdown.getInstance(dropdownFilterAbo);
                        if(ddFilter !== null && $ddBox.hasClass('show')) {
                            $ddBox.removeClass('show');
                            ddFilter.dispose();
                        } else {
                            $ddBox.html('');
                            $ddBox.addClass('d-none');

                        }
                        SURPLEX.SearchAgent.showBoxContent($(this));
                    } else {
                        SURPLEX.SearchAgent.showBoxContent($(this));
                    }
                }
            );
            if (!SURPLEX.App.getIsGuest()) {
                SURPLEX.App.uniqueBindEvent(
                    '.newsletter--btn',
                    'click',
                    'SearchAgent',
                    function(e)
                    {
                        SURPLEX.SearchAgent.showBoxContent($(this), true);
                    }
                );
            }
            SURPLEX.App.uniqueBindEvent(
                '.btn[data-nl-type-id]',
                'click',
                'SearchAgent',
                function(e)
                {
                    e.preventDefault();
                    let $clickElement = $(this);
                    SURPLEX.SearchAgent.subscribeCategoryNewsletter(e, $($clickElement.data('initiator')));
                }
            );

            SURPLEX.App.uniqueBindEvent('.account__notificationsItemTag .account__notificationsItemTagDelete', 'click', 'SearchAgent', function (e) {
                SURPLEX.SearchAgent.removeTagFromSubscription($(this));
            });
            SURPLEX.App.uniqueBindEvent('.js-collection-notification-row .cancel-edit', 'click', 'SearchAgent', function (e) {
                SURPLEX.SearchAgent.resetSubscriptionTags($(this));
            });
            SURPLEX.App.uniqueBindEvent('.js-collection-notification-row .save-edit', 'click', 'SearchAgent', function (e) {
                e.preventDefault();
                SURPLEX.SearchAgent.updateSubscriptionTags($(this));
            });
            SURPLEX.App.uniqueBindEvent('a.js-notification-remove', 'click', 'SearchAgent', function (e) {
                e.preventDefault();
                SURPLEX.SearchAgent.unSubscribe($(this));
            });
        },

        showBoxContent: function ($button, autoSubscribe = false) {
            if (!SURPLEX.App.getIsGuest()) {
                let url = $button.data('url');
                let filter = SURPLEX.Filter.getCurrentFilter();

                // using post because of get param length limitation
                SURPLEX.Ajax.post(
                    {
                        url: SURPLEX.Utils.decodeBase(url),
                        data: {
                            filter: filter
                        },
                        callbackEnd : {},
                        callbackOnSuccess : $.extend(
                            SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                            {
                                'renderSearchAgentContentBox': function (targetObject, result) {
                                    if (result.hasOwnProperty('success') && result.success === true) {
                                        if (result.hasOwnProperty('html')) {
                                            if (result.hasOwnProperty('isModal') && result.isModal === true) {
                                                if ($button.is('.filterBtn') && $($button.data('bs-target')).hasClass('show')) {
                                                    let dropdownFilterAbo = $button.closest('.dropdown').find('.btn').get(0);
                                                    if(dropdownFilterAbo !== null){
                                                        let ddFilter = Dropdown.getOrCreateInstance(dropdownFilterAbo);
                                                        if(ddFilter !== null){
                                                            ddFilter.hide();
                                                        }
                                                    }
                                                }
                                                let $newElem = $(result.html);
                                                $newElem.appendTo("body");
                                                new Modal($newElem.filter( '.modal' ).get(0)).show();
                                                $newElem.filter( '.modal' ).get(0).addEventListener('hidden.bs.modal', function(){
                                                    $newElem.remove();
                                                });
                                            } else {
                                                if ($button.is('.filterBtn')) {
                                                    $($button.data('bs-target')).removeClass('d-none').html(result.html);
                                                    let dropdownFilterAbo = $button.closest('.dropdown').find('.btn').get(0);
                                                    if(dropdownFilterAbo !== null){
                                                        let ddFilter = Dropdown.getOrCreateInstance(dropdownFilterAbo);
                                                        if (ddFilter !== null) {
                                                            ddFilter.show();
                                                            ddFilter.update();
                                                        }
                                                    }
                                                }
                                                if (autoSubscribe) {
                                                    $($button.data('autosubscribe')).get(0).click();
                                                }
                                            }
                                        }
                                        if (result.hasOwnProperty('alreadySubscribed') && result.hasSubscriptions === false && result.alreadySubscribed === false ) {
                                            SURPLEX.Track.trackGA("filterabo", 'filterabo-button', 'filterabo-button-open_noSA');
                                        } else {
                                            SURPLEX.Track.trackGA("filterabo", 'filterabo-button', 'filterabo-button-open_withSA');
                                        }
                                    }
                                    SURPLEX.Register.enableListeners();
                                    SURPLEX.SearchAgent.enableListeners();
                                    SURPLEX.App.enableDataRedirect();
                                }
                            }
                        ),
                        loadingTarget : $button
                    }
                );
            } else {
                SURPLEX.Track.trackGA("filterabo", 'filterabo-button', 'filterabo-button-open');
            }
        },

        subscribeCategoryNewsletter: function (e, $initiator) {
            if (SURPLEX.App.getIsGuest()) {
                SURPLEX.SearchAgent.subscribeCategoryNewsletterLoggedOut(e, $initiator);
            } else {
                SURPLEX.SearchAgent.subscribeCategoryNewsletterLoggedIn(e);
            }
        },
        subscribeCategoryNewsletterLoggedOut: function (e, $initiator) {
            let $target = $(e.target);
            let $subscribeForm = $('#user-newsletter-subscribe-form');
            $subscribeForm.validate({
                errorElement: "div",
                errorClass: "is-invalid",
                validClass: "is-valid",
                showErrors: function (errorMap, errorList) {
                    let i;
                    for (i = 0; errorList[i]; i++) {
                        let error = this.errorList[i];
                        if (error.message.hasOwnProperty('newvalue')) {
                            this.findByName(error.element.name).val(error.message.newvalue);
                            this.errorList[i].message = this.findByName(error.element.name).data('msg-remote');
                        }
                        if (error.message.hasOwnProperty('source')) {
                            SURPLEX.FormValidationErrorTracking.errorMapping.newsletterCategoryModal.msgUniqueEmail['*'] =  'alreadyRegisteredEmail_' + error.message.source;
                            this.errorList[i].message = this.findByName(error.element.name).data('msg-remote');
                        }
                    }
                    this.errorList = $.grep(errorList, function (error) {
                        return error.element !== undefined;
                    });
                    SURPLEX.FormValidationErrorTracking.parseShowErrorList(
                        this.errorList
                    );
                    this.defaultShowErrors();
                },
                errorPlacement: function (error, element) {
                    if (element.data("error-placement")) {
                        error.appendTo(element.data("error-placement"));
                    } else if (element.data("error-placement-before")) {
                        error.insertBefore(element.data("error-placement-before"));
                    } else {
                        error.insertAfter(element);
                    }
                },
                submitHandler: function (form) {
                    const typeId = $target.data('nl-type-id');
                    const type = $target.data('nl-type');
                    let filter = SURPLEX.Filter.getCurrentFilter();
                    let submitUrl = $(form).data('action');
                    if($('#form-group-new-user').hasClass('d-none')){
                        submitUrl = $(form).data('action-user');
                    }
                    if ($(form).data('bc-interests')) {
                        SURPLEX.Register.appendBlueConicInterests($(form));
                    }
                    $(form).appendFingerprint();
                    SURPLEX.Ajax.post(
                        {
                            url: SURPLEX.Utils.decodeBase(submitUrl),
                            data: {
                                userForm: $(form).serialize(),
                                typeId:typeId,
                                type:type,
                                filter: filter,
                            },
                            targetObj : $initiator,
                            callbackOnSuccess: $.extend(
                                {'trackSuccess': function (targetObject, result) {
                                        SURPLEX.Track.trackGA("filterabo", 'filterabo-button', 'filterabo-button-success');
                                }},
                                {'default':SURPLEX.Ajax.getDefaultSuccessCallbacks()}
                            ),
                            callbackEnd : $.extend(
                                {'newsletter': SURPLEX.SearchAgent.categoryNewsletterSubscribed},
                                {'default':SURPLEX.Ajax.getDefaultEndCallbacks()},
                                {
                                    'logInForm': function (targetObject, result) {
                                        if (result.hasOwnProperty('success') && result.success === false ) {
                                            if (result.hasOwnProperty('logInFirst') && result.logInFirst === true) {
                                                let $form = $(form);
                                                let $emailInput = $form.find('input#user-email')
                                                const createUserTag = $emailInput.data('user-create');
                                                const confirmUserTag = $emailInput.data('user-confirm');
                                                $(createUserTag).addClass('d-none');
                                                $(confirmUserTag).removeClass('d-none');
                                            }
                                        }
                                    }
                                }
                            ),
                            loadingTarget : $(form)
                        }
                    );
                },
                highlight: function (element, errorClass, validClass) {
                    let $element;
                    if (element.type === "radio") {
                        $element = this.findByName(element.name);
                    } else {
                        $element = $(element);
                    }
                    $element.removeClass('is-invalid').addClass(errorClass).removeClass(validClass);
                    SURPLEX.FormValidationErrorTracking.parseHighlight(
                        $element[0]
                    );
                },
                unhighlight: function (element, errorClass, validClass) {
                    let $element;
                    if (element.type === "radio") {
                        $element = this.findByName(element.name);
                    } else {
                        $element = $(element);
                    }
                    $element.removeClass('is-invalid').removeClass(validClass).removeClass(errorClass);
                    if ($element.hasClass('required') === true || $element.val().length > 0) {
                        $element.addClass(validClass);
                    }
                    SURPLEX.FormValidationErrorTracking.parseUnhighlight(
                        $element[0]
                    );
                },
            });
        },

        subscribeCategoryNewsletterLoggedIn: function (e) {
            let $target = $(e.target);
            const url = $target.data('subscribe-url');
            const typeId = $target.data('nl-type-id');
            const type = $target.data('nl-type');
            let filter = SURPLEX.Filter.getCurrentFilter();

            SURPLEX.Ajax.post(
                {
                    url: SURPLEX.Utils.decodeBase(url),
                    data: {
                        typeId:typeId,
                        type:type,
                        filter: filter
                    },
                    callbackOnSuccess: $.extend(
                        {'trackSuccess': function (targetObject, result) {
                                SURPLEX.Track.trackGA("filterabo", 'filterabo-button', 'filterabo-button-success');
                            }},
                        {'default':SURPLEX.Ajax.getDefaultSuccessCallbacks()}
                    ),
                    callbackEnd : $.extend({'subscribed': SURPLEX.SearchAgent.newsletterSubscribed}, SURPLEX.Ajax.getDefaultEndCallbacks()),
                    loadingTarget : $target
                }
            );
        },

        newsletterSubscribed: function (targetObject, data, loadingTarget) {
            if (data.hasOwnProperty('success') && data.success === true) {
                let $popUpBox = $('.filterDDMenu');
                $('.btn[data-nl-type-id]').each(function(){
                    let $btn = $(this);
                    if ($btn.data('remove-on-success') !== undefined) {
                        $($btn.data('remove-on-success')).remove();
                    }
                    $btn.remove();
                });
                $('.newsletter--success--block').removeClass('d-none').show();

                if ($popUpBox.hasClass('show')) {
                    window.setTimeout(function () {
                        $popUpBox.addClass("fadeout");
                        window.setTimeout(function () {
                            $popUpBox.removeClass("fadeout");
                            let dropdownFilterAbo = $popUpBox.closest('.dropdown').find('[data-bs-toggle]').get(0);
                            if(dropdownFilterAbo !== null){
                                let ddFilter = Dropdown.getInstance(dropdownFilterAbo) || new Dropdown(dropdownFilterAbo);
                                if(ddFilter !== null){
                                    ddFilter.hide();
                                }
                            }
                        }, 1500);
                    }, 1500);
                }
            }
        },

        categoryNewsletterSubscribed: function(target, returnedData, loadingTarget) {
            if (returnedData.hasOwnProperty('success') && returnedData.success === true) {
                SURPLEX.FormValidationErrorTracking.trackSuccess('#user-newsletter-subscribe-form');
                if (returnedData.hasOwnProperty('replace') && returnedData.replace === true) {
                    loadingTarget.find('.modal-footer button[type="submit"]').addClass('d-none');
                    loadingTarget.find('.modal-body').html(returnedData.html);
                }
                SURPLEX.SearchAgent.newsletterSubscribed(target, returnedData, loadingTarget);
            } else {
                if( returnedData.hasOwnProperty('responseJSON')) {
                    let $errorEl = loadingTarget.find('.error-message');
                    $errorEl.html(returnedData.responseJSON.message);
                    $errorEl.show();
                    SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                        SURPLEX.FormValidationErrorTracking.getFormType('#user-newsletter-subscribe-form'),
                        'global',
                        '',
                        returnedData.responseJSON.message
                    );
                }
            }
        },

        updateSubscriptionTags: function ($element) {
            let $subscriptionRow = $element.parents('.js-collection-notification-row').first();
            let subscriptionId = $subscriptionRow.data('id');
            let filterString = '';


            $subscriptionRow.find('.js-filter-tag-searchAgent').each(function () {
                $(this).find('.js-tag').each(function () {
                    if ($(this).hasClass('disabled') === false) {
                        filterString += '&' + $(this).data('filter');
                    }
                });
            });

            SURPLEX.Ajax.post(
                {
                    url: '/?r=Page/my-notification/update-filter&subscriptionId=' + subscriptionId + '&language=' + SURPLEX.App.pageLanguage,
                    data: {filter: filterString},
                    targetObj : null,
                    loadingTarget : $subscriptionRow,
                    callbackOnSuccess : $.extend(
                        {
                            'updateNotificationTable': function (targetObject, result) {
                                if (result.hasOwnProperty('success')) {
                                    if (result.success === true) {
                                        $subscriptionRow.replaceWith(result.html);
                                        SURPLEX.SearchAgent.enableListeners();
                                    } else {
                                        let settings = {
                                            html: true,
                                            placement: "top",
                                            fallbackPlacements: ['right', 'left', 'top'],
                                            trigger: "focus",
                                            content: result.error.message,
                                            template: '<div class="popover" role="tooltip">' +
                                                            '<div class="popover-arrow"></div>' +
                                                            '<div class="popover-header bg-danger text-white"></div>' +
                                                            '<div class="popover-body py-2"></div>' +
                                                        '</div>'
                                        };
                                        let popOver = Popover.getOrCreateInstance($element.get(0), settings);
                                        $element.attr('data-bs-original-title', "<i class='icon__alert'></i> " + result.error.header);
                                        popOver.show();
                                        $element.get(0).addEventListener('hidden.bs.popover', destroyPopover , true);
                                        function destroyPopover () {
                                            let popOver = Popover.getInstance($element.get(0));
                                            if(popOver !== null){
                                                popOver.dispose();
                                                $element.get(0).removeEventListener('hidden.bs.popover', destroyPopover, true);
                                            }
                                        }
                                    }
                                }
                            }
                        }, SURPLEX.Ajax.getDefaultSuccessCallbacks()
                    )
                }
            )
        },
        resetSubscriptionTags: function ($element) {
            let $subscriptionRow = $element.parents('.js-collection-notification-row').first();
            $subscriptionRow.find('.js-filter-tag-searchAgent').each(function () {
                $(this).find('.js-tag').each(function () {
                    $(this).removeClass('disabled');
                });
            });
            if ($subscriptionRow.find('.js-abo-btns .non-edit').hasClass('d-none')) {
                $subscriptionRow.find('.js-abo-btns .non-edit').removeClass('d-none');
                $subscriptionRow.find('.js-abo-btns .edit').addClass('d-none');
            }
        },
        removeTagFromSubscription: function ($element) {
            let $subscriptionRow = $element.parents('.js-collection-notification-row').first();
            if ($subscriptionRow.find('.js-abo-btns .edit').hasClass('d-none')) {
                $subscriptionRow.find('.js-abo-btns .edit').removeClass('d-none');
                $subscriptionRow.find('.js-abo-btns .non-edit').addClass('d-none');
            }
            $element.parents('.js-tag').first().addClass('disabled');
        },
        unSubscribe: function ($btn) {
            let type = $btn.data('type');
            let $table = $('#js-' + type + '-notification-table');
            let $emptyTag = $('#js-' + type + '-notification-empty');
            let $row = $btn.closest('.js-' + type + '-notification-row');
            let id = $row.data('id');
            let url = $table.data('url');
            SURPLEX.Ajax.post(
                {
                    url: SURPLEX.Utils.decodeBase(url),
                    data: {id: id},
                    targetObj : $btn,
                    loadingTarget : $table,
                    callbackOnSuccess : $.extend(SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                        {
                            'updateNotificationTable':
                                function (targetObject, result) {
                                    let rowCount =  $table.find('tr.js-' + type + '-notification-row').length;
                                    if (rowCount === 1) {
                                        $table.remove();
                                        $emptyTag.removeClass('d-none');
                                    } else {
                                        $row.remove();
                                    }
                                }
                        }
                    )
                }
            )
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.SearchAgent);
    }
})($, SURPLEX, undefined);